import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import * as SocialIcons from "components/SocialIcons";
import Image from "components/Image";
import IconText from "components/IconText";

import "./Footer.scss";

const Footer = ({ frontmatter }) => {

  if (!frontmatter) {
    return null;
  }

  const {
    email,
    phone,
    logos,
    social: { facebook, instagram, linkedin, twitter },
  } = frontmatter;

  // Calcular año actual
  let { copyright } = frontmatter;
  copyright = copyright.replace('CURRENT_YEAR', new Date().getFullYear());

  return (
    <footer className="footer bg-primary">
      <Container>
        <Row className="align-items-center text-center">
          <Col lg={4} className="text-lg-left py-3">
            {copyright}
          </Col>
          <Col lg={4} className="text-lg-center">
            {
              logos.map((logo) => (
                <div className="logo-container" key={logo.imageFileName}>
                  <a href={logo.logoHref} target="_blank" rel="noreferrer">
                    <Image fileName={logo.imageFileName} className={logo.css} alt={logo.logoAlt} />
                  </a>
                </div>
              ))
            }
          </Col>
          <Col lg={4} className="my-3 my-lg-0 text-lg-right">
            <div className="contact-row">
              {twitter ? <SocialIcons.Twitter userName={twitter} className="wrap-logo" /> : null}
              {facebook ? <SocialIcons.Facebook userName={facebook} className="wrap-logo" /> : null}
              {linkedin ? <SocialIcons.Linkedin userName={linkedin} className="wrap-logo" /> : null}
              {instagram ? <SocialIcons.Instagram userName={instagram} className="wrap-logo" /> : null}
            </div>
            {
              email ? <div className="contact-row">
                <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                  <IconText iconName="EnvelopIcon" text={email} iconPosition="left" />
                </a>
              </div>
                : null
            }
            {
              phone ? <div className="contact-row">
                <a href={`tel:${phone}`} target="_blank" rel="noopener noreferrer">
                  <IconText iconName="PhoneIcon" text={phone} iconPosition="left" />
                </a>
              </div>
                : null
            }
          </Col>
        </Row>
      </Container>
    </footer >
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
