import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Fade from "react-reveal/Fade";
import Image from "components/Image";

import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";
import convertMarkdownToHtml from "utils/convertMarkdownToHtml";

import "./Banner.scss";

const Banner = ({ className, frontmatter }) => {

  // Work around para evitar que se muestre el contenido primero y despues el efecto Fade de react-reveal
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  if (!frontmatter) {
    return null;
  }

  const { anchor, message, bannerImage, bannerImageAlt } = frontmatter;

  const mainMessage = (
    <PageSection className={clsx("", className)} id={anchor}>
      <Row>
        <Col lg={12}>
          <Fade right big cascade when={loaded}>
            <div className="main-message" dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(message) }} />
          </Fade>
        </Col>
      </Row>
    </PageSection>
  );

  const parallax = bannerImage ? (
    <ParallaxProvider>
      <ParallaxBanner
        className="parallax"
        layers={[
          {
            children: <Image fileName={bannerImage} alt={bannerImageAlt} />,
            amount: 0.2,
          }
        ]}
        style={{
          // height: '500px'
        }}
      >
        {message ? mainMessage : null}
      </ParallaxBanner>
    </ParallaxProvider>
  ) : null;

  return (
    parallax || mainMessage
  );
};

Banner.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Banner.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Banner;
