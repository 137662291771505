import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Fade from "react-reveal/Fade";

import { Container, Card } from "react-bootstrap";
import Image from "components/Image";
import "./ImageCard.scss";


const ImageCard = ({ className, imageFileName, imageAlt, header, subheader, extraInfo }) => {

  // Work around para evitar que se muestre el contenido primero y despues el efecto Fade de react-reveal
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <Card className={clsx("image-card bg-dark text-white text-center", className)}>
      <Image className="image" fileName={imageFileName} alt={imageAlt || header || subheader} />
      <Card.ImgOverlay className="no-padding">
        <Container>
          <div className="intro-text">
            <div className="intro-heading text-uppercase">
              <Fade right big cascade delay={1000} when={loaded}>
                {header}
              </Fade>
            </div>
            <Fade bottom delay={2000} when={loaded}>
              <div className="intro-lead-in">{subheader}</div>
              {extraInfo}
            </Fade>
          </div>
        </Container>
      </Card.ImgOverlay>
    </Card>
  );
};

ImageCard.propTypes = {
  className: PropTypes.string,
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  extraInfo: PropTypes.any,
};

ImageCard.defaultProps = {
  className: null,
  imageFileName: null,
  imageAlt: null,
  header: "",
  subheader: "",
  extraInfo: null,
};

export default ImageCard;
