import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

import { useFormik } from 'formik';
import * as yup from 'yup';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Row, Col, Button } from "react-bootstrap";
import IconText from "components/IconText";
import Rotate from "react-reveal/Rotate";
import Fade from 'react-reveal/Fade';
import Icon from "components/Icon";
import PageSection from "components/PageSection";
import SectionHeader from "components/SectionHeader";

import "./Contact.scss";

const recaptchaRef = React.createRef();

const Contact = ({ className, frontmatter }) => {

  // Work around para evitar que se muestre el contenido primero y despues el efecto Rotate de react-reveal
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);


  const [reCaptchaValue, setReCaptchaValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openReCaptchaError, setOpenReCaptchaError] = useState(false);

  function onChange(value) {
    // console.log("Captcha value:", value);
    setReCaptchaValue(value);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpenError(false);
    setOpenReCaptchaError(false);
  };

  /* if (!frontmatter) {
    return null;
  } */

  const {
    anchor,
    header,
    subheader,
    reCaptchaKey,
    phone,
    email,
    formDescription,
    formErrorMessage,
    formReCaptchaRequiredMessage,
    formSubmitText,
    formSuccessMessage,
    formInputs,
    callToActionMessage,
    errorMessageMaxLength,
    errorMessageRequired,
    errorMessageRegex
  } = frontmatter;

  // Parsear dinamicamente campos y validaciones
  const validationObject = {};
  formInputs.forEach(input => {

    // Para incluir validacion, se necesita la descripcion
    if (input.name && input.description) {
      let field;

      // Inicializar el elemento yup de tipo string
      if (input.message) {
        field = yup.string(callToActionMessage.replace('[[DESCRIPTION]]', input.description));
      } else {
        field = yup.string();
      }
      if (input.isRequired) {
        field = field.required(errorMessageRequired.replace('[[DESCRIPTION]]', input.description));
      }
      if (input.maxLength) {
        field = field.max(input.maxLength, errorMessageMaxLength.replace('[[DESCRIPTION]]', input.description).replace('[[MAX_LENGTH]]', input.maxLength));
      }
      if (input.regex) {
        field = field.matches(input.regex, errorMessageRegex.replace('[[DESCRIPTION]]', input.description));
      }
      if (input.isEmail) {
        field = field.email(errorMessageRegex.replace('[[DESCRIPTION]]', input.description));
      }
      validationObject[input.name] = field;
    }
  });


  const validationSchema = yup.object(validationObject);

  /* 
  const validationSchema = yup.object({
    "email": yup
      .string('Ingresá tu correo electrónico')
      .email('El correo electrónico no es válido')
      .required('El correo electrónico es requerido'),
    "first_name": yup
      .string('Ingresá tu nombre')
      .max(40, 'El nombre no puede tener mas de 40 caracteres')
      .required('El nombre es requerido'),
    "last_name": yup
      .string('Ingresá tu apellido')
      .max(80, 'El apellido no puede tener mas de 80 caracteres')
      .required('El apellido es requerido'),
    "description": yup
      .string('Ingresá un mensaje')
      .max(1000, 'El mensaje no puede tener mas de 1000 caracteres'),
    "mobile": yup
      .string('Ingresá tu teléfono')
      .required('El teléfono es requerido')
      .max(20, 'El teléfono no puede tener mas de 20 caracteres')
      .matches(/^\+?([0-9]{1,3})?[\s-]?[0-9]{1,8}([\s-])?[0-9]{2,8}([\s-])?[0-9]{2,8}$/, 'El teléfono no es válido'),
  });
  */

  const oid = formInputs.find(x => x.name === 'oid');
  const campaignId = formInputs.find(x => x.name === 'Campaign_ID');
  const company = formInputs.find(x => x.name === 'company');
  const leadSource = formInputs.find(x => x.name === 'lead_source');

  const firstName = formInputs.find(x => x.name === 'first_name');
  const lastName = formInputs.find(x => x.name === 'last_name');
  const formEmail = formInputs.find(x => x.name === 'email');
  const mobile = formInputs.find(x => x.name === 'mobile');
  const description = formInputs.find(x => x.name === 'description');

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      description: '',
      company: company.value,
      lead_source: leadSource.value,
      oid: oid.value,
      Campaign_ID: campaignId.value
    },
    validationSchema,
    onSubmit: (element) => {

      const reCaptchaSubmitValue = recaptchaRef.current.getValue();
      if (reCaptchaSubmitValue !== reCaptchaValue) {
        setOpenReCaptchaError(true);
        return;
      }

      // alert(JSON.stringify(values, null, 2));
      setIsLoading(true);
      let formBody = [];

      const keys = Object.keys(element);
      const values = Object.values(element);
      for (let i = 0; i < keys.length; i += 1) {
        const encodedKey = encodeURIComponent(keys[i]);
        const encodedValue = encodeURIComponent(values[i]);
        formBody.push(encodedKey + "=" + encodedValue);
      }

      formBody = formBody.join("&");

      fetch('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
      })
        .then(response => {
          setOpenSuccess(true);
        })
        .catch(error => {
          setOpenError(true);
        })
        .finally(() => setIsLoading(false));
    },
  });



  const anchorPosition = { vertical: 'top', horizontal: 'right' };

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <Col lg={12} className="mb-3">
          <div className="box contact-form-container">
            <SectionHeader header={header} subheader={subheader} />
            <Row>
              <Col lg={6} className="ml-auto text-center contact-item my-3">
                <Rotate top left duration={1500} when={loaded}>
                  <Icon iconName="PhoneIcon" size="3x" className="text-muted mb-3" />
                  <a className="d-block" href={`tel:${phone}`}>
                    {phone}
                  </a>
                </Rotate>
              </Col>
              <Col lg={6} className="mr-auto text-center contact-item my-3">
                <Rotate top left duration={1500} when={loaded}>
                  <Icon iconName="EnvelopIcon" size="3x" className="text-muted mb-3" />
                  <a className="d-block" href={`mailto:${email}`}>
                    {email}
                  </a>
                </Rotate>
              </Col>
            </Row>

            <hr className="my-3" />

            <form onSubmit={formik.handleSubmit}>

              <input type="hidden" id="company" name="company" />
              <input type="hidden" id="oid" name="oid" />
              <input type="hidden" id="lead_source" name="lead_source" />
              <input type="hidden" id="Campaign_ID" name="Campaign_ID" />

              <p className="form-description text-muted">
                {formDescription}
              </p>

              <Fade bottom duration={1500} when={loaded}>

                <Row>
                  <Col lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={firstName.name}
                      name={firstName.name}
                      label={firstName.description}
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                      helperText={formik.touched.first_name && formik.errors.first_name}
                    />
                  </Col>
                  <Col lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={lastName.name}
                      name={lastName.name}
                      label={lastName.description}
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                      helperText={formik.touched.last_name && formik.errors.last_name}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={formEmail.name}
                      name={formEmail.name}
                      label={formEmail.description}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Col>
                  <Col lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={mobile.name}
                      name={mobile.name}
                      label={mobile.description}
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                      helperText={formik.touched.mobile && formik.errors.mobile}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      id={description.name}
                      name={description.name}
                      label={description.description}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="captcha-container" lg={6}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={reCaptchaKey}
                      onChange={onChange}
                    />
                  </Col>

                  <Col lg={6} className="submit-container" >

                    <Button type="submit" size="xl" variant="primary" className="text-uppercase">
                      <IconText iconName="PaperPlaneIcon" text={formSubmitText} />
                    </Button>
                    <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={anchorPosition}>
                      <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleCloseSnackbar}>
                        {formSuccessMessage}
                      </MuiAlert>
                    </Snackbar>

                    <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={anchorPosition}>
                      <MuiAlert elevation={6} variant="filled" severity="error" onClose={handleCloseSnackbar}>
                        {formErrorMessage}
                      </MuiAlert>
                    </Snackbar>

                    <Snackbar open={openReCaptchaError} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={anchorPosition}>
                      <MuiAlert elevation={6} variant="filled" severity="error" onClose={handleCloseSnackbar}>
                        {formReCaptchaRequiredMessage}
                      </MuiAlert>
                    </Snackbar>
                    {
                      isLoading ? (
                        <span className="loading">
                          <CircularProgress />
                        </span>) : null
                    }
                  </Col>
                </Row>
              </Fade>
            </form>
          </div>

        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
