import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Flip from 'react-reveal/Flip';
import CircleFAButton from "components/CircleFAButton";
import "./FeatureItem.scss";
import convertMarkdownToHtml from "utils/convertMarkdownToHtml";

const FeatureItem = ({ iconName, header, content }) => {

  // Work around para evitar que se muestre el contenido primero y despues el efecto Fade de react-reveal
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  let iconPart;
  const css = "circle-fa-button-primary";
  if (iconName) {
    iconPart = <CircleFAButton iconName={iconName} className={css} />;
  }

  return (
    <Flip left duration={800} delay={300} when={loaded}>
      <div className="box feature-item">
        {iconPart}
        <h4 className="feature-item-heading">{header}</h4>
        <p className="text-muted" dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(content) }} />
      </div>
    </Flip>
  );
};

FeatureItem.propTypes = {
  iconName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

FeatureItem.defaultProps = {
  iconName: null,
  header: "",
  content: "",
};

export default FeatureItem;
