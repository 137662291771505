import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Fade from "react-reveal/Fade";
import CircleFAButton from "components/CircleFAButton";
import Image from "components/Image";
import "./ServiceItem.scss";

const ServiceItem = ({ iconName, imageFileName, header, content }) => {

  // Work around para evitar que se muestre el contenido primero y despues el efecto Fade de react-reveal
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  let iconPart;
  if (iconName) {
    iconPart = <CircleFAButton iconName={iconName} />;
  }

  let imagePart;
  if (imageFileName) {
    imagePart = <Image className="service-item-image" fileName={imageFileName} alt={header} />;
  }

  return (
    <Fade bottom duration={1500} distance="3em" when={loaded}>
      <div className="box service-item">
        {iconPart}
        {imagePart}
        <h4 className="service-item-heading">{header}</h4>
        <p className="text-muted">{content}</p>
      </div>
    </Fade>

  );
};

ServiceItem.propTypes = {
  iconName: PropTypes.string,
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

ServiceItem.defaultProps = {
  iconName: null,
  imageFileName: null,
  header: "",
  content: "",
};

export default ServiceItem;
