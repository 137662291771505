import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import FeatureItem from "components/FeatureItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import "./AboutUs.scss";

const AboutUs = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, secondaryHeader, secondarySubheader, features } = frontmatter;
  const secondaryClassName = "secondary";

  return (

    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <SectionHeader header={secondaryHeader} subheader={secondarySubheader}
          className={secondaryClassName} />
      </Row>
      <Row className="text-center">
        {features.map((feature) => (
          <Col md={4} key={feature.header}>
            <FeatureItem {...feature} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

AboutUs.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

AboutUs.defaultProps = {
  className: null,
  frontmatter: null,
};

export default AboutUs;
