import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Icon from "./Icon";

import "./CircleFAButton.scss";

const CircleFAButton = ({ iconName, className, ...restProps }) => {
  return (
    <div className={clsx("circle-fa-button", className)}>
      <Icon iconName={iconName} inverse size="4x" {...restProps} />
    </div>
  );
};

CircleFAButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CircleFAButton.defaultProps = {
  className: "",
};

export default CircleFAButton;
