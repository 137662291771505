import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Image from "components/Image";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

import "./Portfolio.scss";

const Portfolio = ({ className, frontmatter }) => {

  if (!frontmatter) {
    return null;
  }

  // Se crean las funciones renders custom para poder usar el componente <Image>
  // Y aprovechar las bondades de gatsby images
  const itemRenderer = (galleryItem) => {
    return (
      <div className="image-gallery-item">
        <Image fileName={galleryItem.original}
          alt={galleryItem.originalAlt} />
        {
          galleryItem.description ?
            <span className="image-gallery-description">
              {galleryItem.description}
            </span> : null
        }
      </div>
    )
  };

  const thumbItemRenderer = (galleryItem) => {
    return (
      <div className="image-gallery-thumbnail-inner">
        <Image fileName={galleryItem.thumbnail} alt={galleryItem.thumbnailAlt} className="image-gallery-thumbnail-image" />
        <div className="image-gallery-thumbnail-label">{galleryItem.thumbnailLabel}</div>
      </div>
    )
  };

  const { anchor, header: rootHeader, subheader: rootSubHeader, portfolios } = frontmatter;

  const galleryImages = portfolios.map(
    ({ header, subheader, imageFileName, imageAlt, thumbnailLabel: label }) => {
      return {
        original: imageFileName,
        originalAlt: imageAlt || header,
        originalTitle: header,
        thumbnail: imageFileName,
        thumbnailAlt: imageAlt || header,
        thumbnailLabel: label,
        description: subheader ? header + " - " + subheader : header,
        renderItem: itemRenderer,
        renderThumbInner: thumbItemRenderer,
      };
    }
  );

  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>

      <Row>
        <Col lg={12}>
          <ImageGallery items={galleryImages} showIndex={true} slideInterval={5000} />
        </Col>

        {/* {portfolios.map(
          ({ content, extraInfo, header, imageFileName, imageFileNameDetail, subheader, animation }) => (
            <PortfolioItem
              key={header}
              imageFileName={imageFileName}
              header={header}
              subheader={subheader}
              content={content}
              imageFileNameDetail={imageFileNameDetail}
              extraInfo={
                <ul>
                  {extraInfo.map((ei) => (
                    <li key={ei}>{ei}</li>
                  ))}
                </ul>
              }
              animation={animation}
            />
          ),
        )} */}

      </Row>
    </PageSection>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Portfolio.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Portfolio;
