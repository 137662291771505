import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Navbar from "views/Navbar";
import Top from "views/Top";
import Footer from "views/Footer";
import * as Sections from "views/Sections";
import SEO from "components/SEO";
import LanguageSelector from "components/LanguageSelector";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";
import fileNameToSectionName from "utils/fileNameToSectionName";

import "../style/main.scss";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query IndexQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          anchor
          background
          bannerImage
          bannerImageAlt
          brand
          callToActionMessage
          content
          copyright
          header
          email
          errorMessageMaxLength
          errorMessageRequired
          errorMessageRegex
          features {
            content
            header
            iconName
          }
          formDescription
          formErrorMessage
          formReCaptchaRequiredMessage
          formSubmitText
          formSuccessMessage
          formInputs {
            name
            value
            description
            maxLength
            isEmail
            isRequired
            regex
          }
          imageFileName
          jumpToAnchor
          jumpToAnchorText
          jumpToAnchorIcon
          logo
          logos {
            imageFileName
            logoHref
            logoAlt
            css
          }
          menuText
          message
          portfolios {
            content
            header
            subheader
            imageFileName
            imageAlt
            thumbnailLabel
          }
          privacyHref
          privacyText
          reCaptchaKey
          secondaryHeader
          secondarySubheader
          services {
            content
            header
            iconName
            imageFileName
          }
          social {
            facebook
            linkedin
            twitter
            instagram
          }
          subheader
          teamMember {
            header
            imageFileName
            social {
              facebook
              linkedin
              twitter
              instagram
            }
            subheader
          }
          phone
          termsHref
          termsText
          title
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const IndexPage = ({ data, pageContext: { langKey, defaultLang, langTextMap } }) => {

  useEffect(() => {
    function removeGetButtonLink(tryCounter) {
      // No sabemos cuanto va a tardar en cargar el plugin GetButton
      // Esto es un pequeño hack para quitar el link de propaganda
      const getbuttonLink = document.getElementsByClassName('evMtho')[0];
      if (getbuttonLink) {
        getbuttonLink.remove();
      } else if (tryCounter <= 10) {
        // Maxima cantidad de intentos: 10 veces (10 segundos)
        setTimeout(function () {
          removeGetButtonLink(tryCounter + 1);
        }, 1000);
      }
    }
    const tryCounter = 0;
    removeGetButtonLink(tryCounter);
  });

  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes },
  } = data;

  const { topNode, navBarNode, anchors, footerNode, sectionsNodes } = breakDownAllNodes(nodes);

  // Temporalmente escondemos el selector de idioma. En algún momento usaremos multi idioma
  // const langSelectorPart = null;
  let langSelectorPart = null;
  const pageTitle = langKey === "en" ? "Solar energy" : "Energía solar";
  if (langTextMap != null && Object.keys(langTextMap).length > 1) {
    langSelectorPart = (
      <LanguageSelector langKey={langKey} defaultLang={defaultLang} langTextMap={langTextMap} />
    );
  }

  return (
    <>
      <SEO lang={langKey} title={pageTitle} keywords={keywords} description={description} />
      <Navbar
        anchors={anchors}
        frontmatter={navBarNode.frontmatter}
        extraItems={langSelectorPart}
      />
      <Top frontmatter={topNode.frontmatter} />
      {
        // dynamically import sections
        sectionsNodes.map(({ frontmatter, fields: { fileName } }, ind) => {
          const sectionComponentName = fileNameToSectionName(fileName);
          const SectionComponent = Sections[sectionComponentName];
          const background = frontmatter.background ?? "";

          return SectionComponent ? (
            <SectionComponent
              key={sectionComponentName}
              className={background}
              frontmatter={frontmatter}
            />
          ) : null;
        })
      }
      <Footer frontmatter={footerNode.frontmatter} />

    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

IndexPage.defaultProps = {
  pageContext: {
    langKey: "es",
    defaultLang: "es",
    langTextMap: {},
  },
};

export default IndexPage;
