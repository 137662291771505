import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";
import TeamMember from "components/TeamMember";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper-bundle.min.css'; // TODO: ver por qué no puedo importar scss...!!!
import "./Team.scss";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

const Team = ({ className, frontmatter }) => {

  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    content: rootContent,
    teamMember,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>

      <Row>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            // when window width is >= 900px
            900: {
              slidesPerView: 3,
            }
          }}
          navigation
          pagination={{
            // dynamicBullets: true,
            clickable: true
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true
          }}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        >
          {teamMember.map(({ header, ...tmProps }) => (

            <SwiperSlide key={header}>
              <TeamMember header={header} {...tmProps} />
            </SwiperSlide>
          ))}

        </Swiper>
      </Row>
    </PageSection >
  );
};

Team.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Team.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Team;
