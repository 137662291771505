import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import convertMarkdownToHtml from "utils/convertMarkdownToHtml";
import "./SectionHeader.scss";

const SectionHeader = ({ header, subheader, className, subheaderClassName, boxed, ...restProps }) => {

  const boxedSubheader = (<h5 className={clsx("section-subheading", "text-muted", subheaderClassName)} dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(subheader) }} />);
  const regularSubheader = (<h3 className={clsx("section-subheading", "text-muted", subheaderClassName)} dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(subheader) }} />);

  let subheaderPart = subheader ? regularSubheader : null;
  if (subheader && (boxed === true)) {
    subheaderPart = boxedSubheader;
  }

  const innerContent = (
    <>
      <h2 className="section-heading text-uppercase">{header}</h2>
      {subheaderPart}
    </>
  );
  const fullContent = boxed === true ? (
    <Fade top duration={1500} delay={300} distance="3em">
      <div className="box mb-4">{innerContent}</div>
    </Fade>

  ) : innerContent;

  return (
    <Col lg={12} className={clsx("section-header", "text-center", className)} {...restProps}>
      {fullContent}
    </Col>
  );
};

SectionHeader.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  className: PropTypes.string,
  subheaderClassName: PropTypes.string,
  boxed: PropTypes.bool,
};

SectionHeader.defaultProps = {
  header: "",
  subheader: "",
  className: null,
  subheaderClassName: null,
  boxed: false,
};

export default SectionHeader;
