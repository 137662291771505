

/**
 * converts a string in markdown format, to html
 */
export default function convertMarkdownToHtml(markdown) {

  if (typeof markdown !== 'string') {
    return markdown;
  }

  // Inicialización
  let html = markdown;

  // Match full links and relative paths
  // Tomado de: https://davidwells.io/snippets/regex-match-markdown-links, pero con modificaciones
  const linksMatch = html.match(/\[(.+)\]\((https?:\/\/[\w\d.\-/?=#%+&]+)\)/)

  if (linksMatch) {
    // de-structure the array
    const [linkFull, linkText, linkUrl] = linksMatch
    if (linkFull) {
      html = html.replace(linkFull, "<a href='" + linkUrl + "' target='_blank'>" + linkText + "</a>");
    }
  }

  // Match bold strings
  const boldMatch = html.match(/(\*\*)(\s*\b)([^*]*)(\b\s*)(\*\*)/g)
  if (boldMatch) {
    boldMatch.forEach(element => {
      html = html.replace(element, "<strong>" + element.replace(/\*\*/g, "") + "</strong>");
    });
  }

  if (html !== markdown) {
    // Recursivo hasta que no se encuentren mas cambios ?
    // html = convertMarkdownToHtml(html);
  }
  return html;
}
